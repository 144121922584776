<template>
	<div class="mt-5">
		<!-- Note we are not restricting with :filters="{library: true}" as we can also accept .scrn files -->
		<tera-file-select
			:title="'Choose a citation library to screen...'"
			:filters="{}"
			placeholder="Choose a citation library to screen..."
			@change="fileUpload"
			class="my-2"
			style="background: #FFF"
		/>
		<!-- Option for a user to restore state from another JSON file -->
		<b-button @click="selectNewJsonFile()" class="mt-2">Recover state from another json file (warning: may lose data)</b-button>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin"
import loadMixin from "../mixins/loadMixin"
import TeraFileSelect from '@iebh/tera-fy/widgets/tera-file-select.vue';
import store, { teraSyncPlugin } from '../store'

export default {
	components: {
		TeraFileSelect,
	},
	name: 'FileUpload',
	mixins: [importMixin, loadMixin],
	methods: {
		async fileUpload(file) {
			if (file.parsedName.ext === 'scrn') {
				return this.loadFile(file);
			} else {
				return this.importFile(file);
			}
		},
		async selectNewJsonFile() {
			return await teraSyncPlugin(store).promptForNewJsonFile();
		}
	}
}
</script>
