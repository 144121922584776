<template>
      <div>
            <!--Eligibility Criteria Modal-->
            <b-modal  ref="criteriaModal" size="lg" title="Eligibility Criteria Table" @hide="closeCriteria">
                  <div class="d-flex">
                        <b-card header-bg-variant="success" header-text-variant="white" header="INCLUSION" class="flex-fill" style="width:50%">
                              <b-card-text v-for="(include,index) in includesWithField" :key="index">
                                   <div v-if="include!=null">
                                          <label class="text-secondary">{{ include.Criteria}}</label>
                                          <b-list-group v-for="(inc, index) in include.Keywords" :key="index">
                                              <b-list-group-item class="text-primary" variant="primary">{{ inc }}</b-list-group-item>
                                          </b-list-group>
                                   </div>
                                    <!--<b-list-group-item button variant="success">Add Inclusion</b-list-group-item>-->
                              </b-card-text>
                              <b-card-text class="text-center font-weight-bold text-info font-italic" v-if="!hasIncludes">There's no inclusion criteria added from {{criteriaType}}</b-card-text>
                        </b-card>
                        <b-card header-bg-variant="warning" header-text-variant="white" header="EXCLUSION" class="flex-fill" style="width:50%">
                               <b-card-text v-for="(exclude,index) in excludesWithField" :key="index">
                                    <div v-if="exclude!=null">
                                          <label class="text-secondary">{{ exclude.Criteria }}</label>
                                          <b-list-group v-for="(exc, index) in exclude.Keywords" :key="index">
                                                <b-list-group-item class="text-primary" variant="primary">{{ exc }}</b-list-group-item>
                                          </b-list-group>
                                    </div>
                                    <!--<b-list-group-item button variant="warning">Add Exclusion</b-list-group-item>-->
                               </b-card-text>
                               <b-card-text class="text-center font-weight-bold text-info font-italic" v-if="!hasExcludes">There's no exclusion criteria added from {{criteriaType}}</b-card-text>
                        </b-card>
                  </div>
                  <template #modal-footer>
                        <div v-if="manageKeywords" style="width:100%">
                                    <b-button-close @click="manageKeywords=false" class="close-btn"/>
                                    <label for="inclusion-tags">Include Keywords</label>
                                    <b-form-tags input-id="inclusion-tags" v-model="inclusionList"></b-form-tags>
                                    <label for="exclusion-tags">Exclude Keywords</label>
                                    <b-form-tags input-id="exclusion-tags" v-model="exclusionList"></b-form-tags>
                         </div>
                        <b-button variant="primary" size="sm" @click="manageKeywords=true" v-if="!manageKeywords">Show Keywords</b-button>
                        <b-button-group>
                              <b-button variant="success" size="sm" :disabled="exclusionList.length==0 && inclusionList.length==0" @click="addIncludeExclude">Add All</b-button>
                              <b-button variant="danger" size="sm" @click="closeCriteria">Close</b-button>
                        </b-button-group>
                  </template>
            </b-modal>
      </div>
</template>
<script>
export default {
      data() {
            return {
                  criteriaType: String,
                  inclusionList: Array,
                  exclusionList: Array,
                  includesWithField: Array,
                  excludesWithField: Array,
                  hasExcludes: false,
                  hasIncludes:false,
                  manageKeywords: true,
                  reviewTypes:["systematic-review","academy-protocol","umbrella-review","network-meta-analysis","meta-analysis"]
            }
      },
      mounted() {
            this.criteriaList();
      },
      methods: {
            //Eligibility Criteria Popup Modal
            openCriteriaModal() {
                  this.$refs.criteriaModal.show();
            },
            closeCriteria() {
                  this.$refs.criteriaModal.hide();
            },
             criteriaList() {
                  let inclList = [];
                  let exclList = [];
                  //ReviewWizard
                  if (this.reviewTypes.includes(this.$tera.project.type) ){
                        this.criteriaType = `PICOST(${this.$tera.project.type})`

                        inclList.push(this.fieldList("Population/s,Problem/s (P)", this.checkInclusive(this.$tera.project.population, true)));
                        inclList.push(this.fieldList("Intervention/s (I)", this.checkInclusive(this.$tera.project.intervention, true)));
                        inclList.push(this.fieldList("Comparators (C)", this.checkInclusive(this.$tera.project.comparator, true)));
                        inclList.push(this.fieldList("Outcomes (O)", this.checkInclusive(this.$tera.project.outcomes, true)));
                        inclList.push(this.fieldList("Setting (S)", this.checkInclusive(this.$tera.project.setting, true)));
                        inclList.push(this.fieldList("Types (T)", this.$tera.project.types.map(t => t.label)));
                        inclList.push(this.fieldList("Languages", this.$tera.project.includedLanguages.map(t => t.label)));

                        exclList.push(this.fieldList("Population/s,Problem/s (P)", this.checkInclusive(this.$tera.project.population, false)));
                        exclList.push(this.fieldList("Intervention/s (I)", this.checkInclusive(this.$tera.project.intervention, false)));
                        exclList.push(this.fieldList("Comparators (C)", this.checkInclusive(this.$tera.project.comparator, false)));
                        exclList.push(this.fieldList("Outcomes (O)", this.checkInclusive(this.$tera.project.outcomes, false)));
                        exclList.push(this.fieldList("Setting (S)", this.checkInclusive(this.$tera.project.setting, false)));
                        exclList.push(this.fieldList("Publication Types", this.$tera.project.excludedPublicationTypes.map(t => t.label)));

                  }
                  //ScopingWizard
                  if (this.$tera.project.type == "scoping-review") {
                        this.criteriaType = "PCCTT(Scoping Wizard)"

                        inclList.push(this.fieldList("Participants/Population/Problems (P)", this.checkInclusive(this.$tera.project.population, true)))
                        inclList.push(this.fieldList("Concepts (C)", this.checkInclusive(this.$tera.project.ScopingWizard.concept, true)))
                        inclList.push(this.fieldList("Context (C)", this.checkInclusive(this.$tera.project.ScopingWizard.context, true)))
                        inclList.push(this.fieldList("Themes (T)", this.checkInclusive(this.$tera.project.ScopingWizard.theme, true)))
                        inclList.push(this.fieldList("Types (T)", this.$tera.project.types.map(t => t.label)))
                        inclList.push(this.fieldList("Languages", this.$tera.project.includedLanguages.map(t => t.label)))

                        exclList.push(this.fieldList("Participants/Population/Problems (P)", this.checkInclusive(this.$tera.project.population, false)))
                        exclList.push(this.fieldList("Concepts (C)", this.checkInclusive(this.$tera.project.ScopingWizard.concept, false)))
                        exclList.push(this.fieldList("Context (C)", this.checkInclusive(this.$tera.project.ScopingWizard.context, false)))
                        exclList.push(this.fieldList("Themes (T)", this.checkInclusive(this.$tera.project.ScopingWizard.theme, false)))
                        exclList.push(this.fieldList("Publication Types", this.$tera.project.excludedPublicationTypes.map(t => t.label)))

                  }
                  this.hasExcludes = exclList.some(i => i != null);
                  this.hasIncludes = inclList.some(i => i != null);
                  this.includesWithField = inclList;
                  this.excludesWithField = exclList;
                  this.inclusionList = this.includesWithField.map( f =>{if(f!=null) return f.Keywords}).flat();
                  this.exclusionList = this.excludesWithField.map(f => { if (f != null) return f.Keywords }).flat();
            },
            fieldList(criteria, l) {
                  return l.length > 0 ? { Criteria: criteria, Keywords: l } : null;
            },
            checkInclusive(arr, include) {
                  let incl = [];
                  let excl = [];
                  arr.forEach(a => {
                        if (a.inclusion && a.main != undefined) {
                              incl.push(a.main);
                        }
                        if (!a.inclusion && a.main != undefined) {
                              excl.push(a.main);
                        }
                  });
                  if (include) {
                        return incl;
                  } else {
                        return excl;
                  }
            },
            addIncludeExclude() {
                  this.$store.commit('keywords/setInclude', this.inclusionList);
                  this.$store.commit('keywords/setExclude', this.exclusionList);
                  this.closeCriteria();
            }
      }
}
</script>
