import Vue from 'vue'
import Vuex from 'vuex'
import references from './modules/references'
import metadata from './modules/metadata'
import groups from './modules/groups'
import hotkeys from './modules/hotkeys'
import loading from './modules/loading'
import keywords from './modules/keywords'
import tableFields from './modules/tableFields'
import { createSyncPlugin } from '@iebh/vuex-tera-json'

Vue.use(Vuex)

// Create TERA sync plugin with configuration
export const teraSyncPlugin = createSyncPlugin('screenatron', true, {
	debounceMs: 100
})

export default new Vuex.Store({
	modules: {
		metadata,
		references,
		groups,
		hotkeys,
		loading,
		keywords,
		tableFields
	},
	plugins: [teraSyncPlugin],
	actions: {
		addRefsToGroup(state, payload) {
			let groupKey = payload.groupKey;
			let refs = payload.refs;
			state.commit('references/updateRefs', refs);
			state.commit('groups/removeRefsFromGroups', refs);
			state.commit('groups/pushRefsToGroup', { groupKey, refs });
		},
		removeGroup(context, group) {
			context.commit('groups/setSelectedGroup', "all");
			context.commit('hotkeys/removeHotkeys', group.key);
			context.commit('references/removeGroups', group.refIds);
			context.dispatch('groups/removeGroup', group);
		},
		resetStore({ commit }) {
			commit('metadata/reset');
			commit('groups/reset');
			commit('hotkeys/reset');
			commit('references/reset');
			commit('keywords/resetInclude');
			commit('keywords/resetExclude');
		}
	}
})
