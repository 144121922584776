export default {
	methods: {
		loadFile(file) {
			return Promise.resolve()
				.then(()=> this.$store.commit('loading/setLoading', true))
				.then(()=> file || this.$tera.selectProjectFile({
					title: 'Load screening file',
					hint: 'screening',
				}))
				.then(file => file.getContents({format: 'json'}))
				.then(data => {
					if (data.metadata) {
						this.$store.commit('metadata/setMetadata', data.metadata);
					} else {
						this.$store.commit('metadata/reset');
					}
					if (data.refs) {
						this.$store.commit('references/setRefs', data.refs);
					} else {
						this.$store.commit('references/reset');
					}
					if (data.groupList) {
						this.$store.commit('groups/setGroupList', new Map(data.groupList));
					} else {
						this.$store.commit('groups/reset');
					}
					if (data.hotkeys) {
						this.$store.commit('hotkeys/setHotkeys', new Map(data.hotkeys));
					} else {
						this.$store.commit('hotkeys/reset');
					}
					if (data.includeKeywords) {
						this.$store.commit('keywords/loadInclude', data.includeKeywords);
					} else {
						this.$store.commit('keywords/resetInclude');
					}
					if (data.excludeKeywords) {
						this.$store.commit('keywords/loadExclude', data.excludeKeywords);
					} else {
						this.$store.commit('keywords/resetExclude');
					}
					this.$store.commit('loading/setLoading', false);

					//this.$store.dispatch('resetStore').then(reader.readAsText(file));
				})
		},
	}
}
